// @flow
import { wordToTitle } from '..';

export type TBreadcrumb = {
    label: string;
    path: string;
};

/**
 * @description divides path to words
 *
 * @example:
 *  pathToWords("my-best_page");
 * -> ["my", "best", "page"]
 */
export const pathSegmentToWords = (segment: string): Array<string> =>
    segment.replace(/[-_]/g, ' ').split(' ');

/**
 * @description transforms path to title form
 *
 * @example:
 *  pathToTitle("my-best_page");
 * -> "My Best Page"
 */
export const pathSegmentToTitle = (segment: string): string =>
    pathSegmentToWords(segment).map(wordToTitle).join(' ');

/**
 * @description generates breadcrumbs from url
 *
 * @example:
 *  urlToBreadcrumbs("/page/my-best");
 * -> [{label: "Page", path: "/page"}, {label: "My Best", path: "/page/my-best"}]
 */
export const pathToBreadcrumbs = (path: string): Array<TBreadcrumb> =>
    path
        .split('/')
        .filter(segment => !!segment)
        .map((segment, idx, arr) => ({
            label: pathSegmentToTitle(segment),
            path: `/${arr.slice(0, idx + 1).join('/')}`,
        }));
