// @flow
import React from 'react';
import gql from 'graphql-tag';
import * as R from 'ramda';

import { FRAGMENT_PLOTS_TOTAL } from '@project/fragments/global';
import ControlledQuery from '@project/components/ControlledQuery';
import Menu from './index';

const QUERY_GET_TOTALS = gql`
    query getTotals {
        getTotals {
            ...PlotsCountData
        }
    }
    ${FRAGMENT_PLOTS_TOTAL}
`;

const NavigationMenuGQL = () => (
    <ControlledQuery query={QUERY_GET_TOTALS}>
        {({ data }) => {
            const plotsTotal = R.path(['getTotals', 'plots'], data);

            return <Menu plotsTotal={plotsTotal} />;
        }}
    </ControlledQuery>
);

export default NavigationMenuGQL;
