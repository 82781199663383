// @flow
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import Menu from '@project/components/Menu';

import Auth0 from '../../../services/Auth0';

import type { Location, RouterHistory } from 'react-router-dom';

type Props = {
    location: Location;
    history: RouterHistory;
    beforeNavigate: Function;
};

export class HeaderUserMenu extends Component<Props> {
    handleOnClick = ({ key }: GenericObject): void => {
        const { history, beforeNavigate } = this.props;

        if (key === '/log-out') {
            beforeNavigate();

            return Auth0.logout();
        }

        beforeNavigate();
        history.push(key);
    };

    render() {
        const { location } = this.props;

        return (
            <Menu
                style={{ width: 200 }}
                selectedKeys={[location.pathname]}
                onSelect={this.handleOnClick}
                className="borderless"
                items={[
                    {
                        key: '/my-account',
                        label: 'My Account',
                    },
                    {
                        key: '/log-out',
                        label: 'Log Out',
                    },
                ]}
            />
        );
    }
}

export default withRouter(HeaderUserMenu);
