// @flow
import { message as antdMessage } from 'antd';

import { serviceMock } from './mocks';

type TLogger = {
    DISPLAY_MESSAGE: 100;
    info: (message: string, messageType?: number) => void;
    warning: (message: string, error: Error, messageType?: number) => void;
    error: (message: string, error: Error, messageType?: number) => void;
    // ? alert
    // ? critical
};

type TInfoTypes = 'success' | 'info' | 'warning' | 'error';

const logger: TLogger = (service => {
    // We may support more types of notification components, that is why it is not boolean
    // example: https://ant.design/components/notification/
    const DISPLAY_MESSAGE = 100;

    /**
     * To display Ant's Message Component
     * @see https://ant.design/components/message/
     */
    const displayMessage = (message: string, type: TInfoTypes = 'info') => {
        antdMessage[type](message);
    };

    return {
        DISPLAY_MESSAGE,
        info: (message, messageType = 0) => {
            console.info(`[INFO]: ${message}`); // eslint-disable-line no-console
            if (messageType === DISPLAY_MESSAGE) displayMessage(message, 'info');
        },
        warning: (message, error, messageType = 0) => {
            service.log(message, error);

            console.warn(`[WARNING]: ${message}`);
            if (messageType === DISPLAY_MESSAGE) displayMessage(message, 'warning');
        },
        error: (message, error, messageType = 0) => {
            service.log(message, error);

            console.error(`[ERROR]: ${message}`);
            if (messageType === DISPLAY_MESSAGE) displayMessage(message, 'error');
        },
    };
})(serviceMock);

export default logger;
