// @flow
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Query } from '@apollo/react-components';
import * as R from 'ramda';
import * as Sentry from '@sentry/browser';

import { isStaging, isProduction } from '@project/utils';

import type { Location, RouterHistory } from 'react-router-dom';

type Props = {
    query: string;
    children: Function;
    location: Location;
    history: RouterHistory;
    fetchPolicy?: string;

    variables?: GenericObject;
    skip?: boolean;
    /**
     * @description a custom callback to trigger a custom behavior when error occurs
     * @example display alert
     */
    onError?: (v: GenericObject) => any;
    /**
     * @description a custom callback to trigger a custom behavior when entity is not found
     * @example display alert
     */
    onNotFound?: (v: GenericObject) => any;
};

export class ControlledQuery extends Component<Props> {
    static defaultProps = {
        onError: undefined,
        onNotFound: undefined,
        skip: false,
        variables: undefined,
        fetchPolicy: undefined,
    };

    getNotFoundPath = () => {
        const {
            location: { pathname },
        } = this.props;

        return `${pathname.slice(0, pathname.lastIndexOf('/'))}/not-found`;
    };

    handleError = (error: GenericObject) => {
        const { history, onError, onNotFound } = this.props;
        console.error('[ERROR]: ', error);

        // TODO: when we define BE errors shape, this should be refactored, it's just as an example now
        const errorMessage = R.pathOr('', ['graphQLErrors', 0, 'data', 'originalMessage'], error);

        if (errorMessage.includes('not found') || errorMessage.includes('invalid input syntax')) {
            if (typeof onNotFound === 'function') onNotFound(error);

            history.push(this.getNotFoundPath());
            return;
        }

        if (typeof onError === 'function') onError(error);

        if (isStaging() || isProduction()) {
            Sentry.captureException(error);
        }
    };

    render() {
        const { query, variables, skip, children, fetchPolicy } = this.props;

        return (
            <Query
                query={query}
                variables={variables}
                skip={skip}
                onError={this.handleError}
                fetchPolicy={fetchPolicy}
            >
                {props => children(props)}
            </Query>
        );
    }
}

export default withRouter(ControlledQuery);
