import React from 'react';
import {
    HomeOutlined,
    FolderOutlined,
    SyncOutlined,
    CheckCircleOutlined,
    MailOutlined,
    SolutionOutlined,
    TeamOutlined,
    DatabaseOutlined,
    UploadOutlined,
    BoxPlotOutlined,
} from '@ant-design/icons';
import { Link, withRouter } from 'react-router-dom';
import * as R from 'ramda';

import Menu from '@project/components/Menu';

import * as Styled from './styledComponents';

import { PARTNER_CATEGORIES } from '../../pages/Partners/constants';
import { PLOT_CATEGORIES } from '../../pages/Plots/constants';
import { MARKETPLACE_CATEGORIES } from '../../pages/Marketplace/constants';
import { LogoWithTitle } from '@project/assets/images';

const pathWithMaxDepth = (path, maxDepth) => path.split('/').slice(0, maxDepth).join('/');

type Props = {
    location: Location;
    history: any;
    plotsTotal: {
        new: number;
        propertyAssigned: number;
        ready: number;
        listed: number;
        underContract: number;
        ownedByInvestor: number;
    };
};

export const NavigationMenu = ({ history, location, plotsTotal }: Props) => (
    <div>
        <Link to="/properties" style={{ textDecoration: 'none' }}>
            <Styled.LogoWrapper>
                <Styled.Logo alt="logo" src={LogoWithTitle} />
            </Styled.LogoWrapper>
        </Link>
        <Menu
            mode="inline"
            selectedKeys={[pathWithMaxDepth(location.pathname, 3)]}
            onClick={({ key }) => history.push(key)}
            items={[
                {
                    key: '/properties',
                    icon: <HomeOutlined />,
                    label: 'Properties',
                },
                {
                    key: '/plots',
                    icon: <FolderOutlined />,
                    label: 'Plots',
                    children: PLOT_CATEGORIES.map(({ status, path, label }) => ({
                        key: `/plots/${path}`,
                        label: `${label} (${R.pathOr(0, [status], plotsTotal)})`,
                    })),
                },
                {
                    key: '/marketplace',
                    icon: <SyncOutlined />,
                    label: 'Marketplace',
                    children: MARKETPLACE_CATEGORIES.map(({ status, path, label }) => ({
                        key: `/marketplace/${path}`,
                        label: `${label} (${R.pathOr(0, [status], plotsTotal)})`,
                    })),
                },
                {
                    key: '/verifications',
                    icon: <CheckCircleOutlined />,
                    label: 'Verifications',
                },
                {
                    key: '/partners',
                    icon: <MailOutlined />,
                    label: 'Partners',
                    children: PARTNER_CATEGORIES.map(({ path, label }) => ({
                        key: `/partners/${path}`,
                        label,
                    })),
                },
                {
                    key: '/investors',
                    icon: <SolutionOutlined />,
                    label: 'Investors',
                },
                {
                    key: '/users',
                    icon: <TeamOutlined />,
                    label: 'Users',
                },
                {
                    key: '/promo',
                    icon: <BoxPlotOutlined />,
                    label: 'Promo Codes',
                },
                {
                    key: '/services',
                    icon: <UploadOutlined />,
                    label: 'Services',
                    children: [
                        {
                            key: '/services/utilities',
                            icon: <DatabaseOutlined />,
                            label: 'Utilities',
                        },
                    ],
                },
            ]}
        />
    </div>
);

export default withRouter(NavigationMenu);
