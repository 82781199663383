// @flow
export const MARKETPLACE_STATUSES = {
    LISTED: 'listed',
    UNDER_CONTRACT: 'underContract',
    OWNED_BY_INVESTOR: 'ownedByInvestor',
};

export const MARKETPLACE_CATEGORIES = [
    { status: MARKETPLACE_STATUSES.LISTED, path: 'listed', label: 'Listed' },
    {
        status: MARKETPLACE_STATUSES.UNDER_CONTRACT,
        path: 'requested-purchase',
        label: 'Requested Purchase',
    },
    {
        status: MARKETPLACE_STATUSES.OWNED_BY_INVESTOR,
        path: 'holding',
        label: 'Holding',
    },
];
