// @flow
import styled from 'styled-components';

export const Container = styled.div`
    border-top: 1px solid rgba(0, 0, 0, 0.33);
    padding: 4px 16px;
`;

export const Info = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 4px 0;
    font-size: 12px;
`;

export const Label = styled.div`
    font-weight: bold;
`;

export const Anchor = styled.a`
    margin-left: 4px;
`;
