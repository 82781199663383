import React, { Component } from 'react';
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import * as Sentry from '@sentry/browser';

import * as Styled from './styledComponents';

import { isStaging, isProduction } from '@project/utils';

type Props = {
    username: string;
    id: string;

    avatar?: string;
    openMenu: () => void;
};

class AvatarWithUsername extends Component<Props> {
    static defaultProps = {
        avatar: '',
    };

    componentDidMount() {
        const { id, username } = this.props;

        if ((isStaging() || isProduction()) && id) {
            Sentry.configureScope(scope => {
                scope.setUser({ username, id });
            });
        }
    }

    componentDidUpdate(prevProps: Props) {
        const { id, username } = this.props;

        if ((isStaging() || isProduction()) && prevProps.id !== id) {
            Sentry.configureScope(scope => {
                scope.setUser({ username, id });
            });
        }
    }

    render() {
        const { username, avatar, openMenu } = this.props;

        return (
            <Styled.ContainerButton onClick={openMenu}>
                <span>{username}</span>
                <Avatar size="small" {...(avatar ? { src: avatar } : { icon: <UserOutlined /> })} />
            </Styled.ContainerButton>
        );
    }
}

export default AvatarWithUsername;
