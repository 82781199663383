import React, { useState } from 'react';
import { Popover, Typography } from 'antd';

import UserMenu from './UserMenu';
import AvatarWithUsernameGQL from './AvatarWithUsername/graphql';

import * as Styled from './styledComponents';

import { isLocalBuild, isStaging, isDevelopment } from '@project/utils';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { pathToBreadcrumbs } from '@project/utils/URLParsers';
import {
    WarningFilled
} from '@ant-design/icons';

const Header = () => {
    const { pathname } = useLocation();
    const title = pathToBreadcrumbs(pathname);
    const [menuVisible, setMenuVisible] = useState(false);

    const openUserMenu = () => setMenuVisible(true);

    const closeUserMenu = () => setMenuVisible(false);

    const getDeprecatedWarning =(t: any) =>  {

        let title = t[t.length-1]?.label
        if(title === 'Properties') {
            return <div style={{fontWeight: 'bold', color: 'red'}}><WarningFilled style={{ color: 'red' }}/>{' '}Changing Properties data in the admin is now deprecated and should be avoided.
            You can make changes in <a target='_blank' href='https://plotify.retool.com/apps/c33807ee-fe51-11ee-81d8-e727089ca05f/7.%20Property%20%26%20Plots/Properties'>Retool</a>
            </div>
        } else if(t[t.length-2]?.label === 'Plots') {
            return <div style={{fontWeight: 'bold', color: 'red'}}><WarningFilled style={{ color: 'red' }}/>{' '}Changing Plots data in the admin is now deprecated and should be avoided.
            You can make changes in <a target='_blank' href='https://plotify.retool.com/apps/9776f112-fe54-11ee-99ce-737f649a8d2f/7.%20Property%20%26%20Plots/Plots'>Retool</a>
            </div>
        } 
        return ''
    }

    return (
        <Styled.Container>
            <h1>{title?.length && title[title.length-1]?.label} </h1><div>{getDeprecatedWarning(title)}</div>
            <Popover
                style={{ width: 400 }}
                open={menuVisible}
                trigger="contextMenu"
                onOpenChange={closeUserMenu}
                content={<UserMenu beforeNavigate={closeUserMenu} />}
                placement="bottomRight"
            >
                <AvatarWithUsernameGQL openMenu={openUserMenu} />
            </Popover>
        </Styled.Container>
    );
};

export default Header;
