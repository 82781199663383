// @flow
import gql from 'graphql-tag';

export const FRAGMENT_CURRENCY_VALUE = gql`
    fragment CurrencyValueData on CurrencyValue {
        value
        code
    }
`;

export const FRAGMENT_DATE_TIME_RANGE = gql`
    fragment DateTimeRangeData on DateTimeRange {
        min
        max
    }
`;

export const FRAGMENT_RESTRICTED_COUNTRY = gql`
    fragment CountryData on Country {
        id
        name
        isoCode
    }
`;

export const FRAGMENT_RESTRICTED_STATE = gql`
    fragment StateData on State {
        id
        name
        code
    }
`;

export const FRAGMENT_FREE_COUNTRY = gql`
    fragment FreeCountryData on FreeCountry {
        id
        name
        isoCode
    }
`;

export const FRAGMENT_FREE_STATE = gql`
    fragment FreeStateData on FreeState {
        id
        name
        code
    }
`;

export const FRAGMENT_LIST_META = gql`
    fragment ListMetadata on Meta {
        total
        limit
        offset
    }
`;

export const FRAGMENT_GEOLOCATION = gql`
    fragment GeolocationData on Coordinates {
        latitude
        longitude
    }
`;

export const FRAGMENT_RESTRICTED_ADDRESS = gql`
    fragment RestrictedAddressData on RestrictedAddress {
        country {
            ...CountryData
        }
        # State or Province
        administrativeArea {
            ...StateData
        }
        # City
        localityName
        # Locality (suburb, ...)
        dependentLocalityName
        # Address 1
        thoroughfare
        # Address 2
        premise
        # Zip Code
        postalCode
        #
        geolocation {
            ...GeolocationData
        }
        googlePlacesId
        # Formatted Address received from Google Map Suggestions API
        formattedAddress
    }
    ${FRAGMENT_RESTRICTED_COUNTRY}
    ${FRAGMENT_RESTRICTED_STATE}
    ${FRAGMENT_GEOLOCATION}
`;

export const FRAGMENT_FREE_ADDRESS = gql`
    fragment FreeAddressData on FreeAddress {
        country {
            ...FreeCountryData
        }
        # State or Province
        administrativeArea {
            ...FreeStateData
        }
        # City
        localityName
        # Locality (suburb, ...)
        dependentLocalityName
        # Address 1
        thoroughfare
        # Address 2
        premise
        # Zip Code
        postalCode
        #
        geolocation {
            ...GeolocationData
        }
        googlePlacesId
        # Formatted Address received from Google Map Suggestions API
        formattedAddress
        timezone
    }
    ${FRAGMENT_FREE_COUNTRY}
    ${FRAGMENT_FREE_STATE}
    ${FRAGMENT_GEOLOCATION}
`;

// TOTAL COUNTS FOR NAVIGATION MENU
export const FRAGMENT_PLOTS_TOTAL = gql`
    fragment PlotsCountData on EntityCounts {
        plots {
            new
            propertyAssigned
            ready
            listed
            underContract
            ownedByInvestor
        }
    }
`;
