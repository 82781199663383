// @flow
export const PLOT_STATUSES = {
    NEW: 'new',
    PROPERTY_ASSIGNED: 'propertyAssigned',
    READY: 'ready',
};

export const PLOT_CATEGORIES = [
    { status: PLOT_STATUSES.NEW, path: 'new', label: 'New' },
    {
        status: PLOT_STATUSES.PROPERTY_ASSIGNED,
        path: 'property-assigned',
        label: 'Property Assigned',
    },
    { status: PLOT_STATUSES.READY, path: 'ready-to-list', label: 'Ready To List' },
];

export const INVESTOR_TYPES = {
    DEFAULT: 'Institutional Default',
};

/** Rather a temporary solution because of the 1:1 mapping with the BE structure */
export const GLOBAL_PLOT_FORM_FIELD_NAMES = new Proxy(
    {
        plotName: 'Plot ID',
        address: 'Corporate Address',
        incorporationCountry: 'Country of incorporation',
        settlementDate: 'Settlement Date',
        ownershipDate: 'Ownership Date',
        incorporationDate: 'Incorporation Date',
        operatingDate: 'Operating Date',
        'address.administrativeArea': 'State',
        'address.localityName': 'City',
        'address.dependentLocalityName': 'Locality',
        'address.thoroughfare': 'Address 1',
        'address.premise': 'Address 2',
    },
    {
        get: (obj, prop) => {
            if (prop in obj) {
                return obj[prop];
            }
            const keys = prop.split('.');
            return keys[keys.length - 1].split(/(?=[A-Z])/).join(' ');
        },
    }
);

export const TIMEZONE_REGEX = /^(-|\+)?1?[0-9]$/;
