// @flow
import React from 'react';
import { Query } from '@apollo/react-components';
import gql from 'graphql-tag';
import moment from 'moment';

import * as Styled from './styledComponents';

import { getAdminPortalVersion, isDevelopment, isLocalBuild, isStaging } from '@project/utils';

const DATE_FORMAT = 'YYYY/MM/DD HH:mm:ss';
const POLL_INTERVAL = 60 * 1000; // 1 min

// TODO: fetch versions
const QUERY_SERVER_INFO = gql`
    query serverInfo {
        databaseResetTime
        deployDateTime
        buildHash
        version {
            currentVersion
        }
    }
`;

const ADMIN_BFF_REPOSITORY_COMMIT_LINK = 'https://github.com/oakslab/plotify-bff-admin/commit/';
const ADMIN_BFF_REPOSITORY_RELEASES_LINK =
    'https://github.com/oakslab/plotify-bff-admin/releases/tag/v';
const ADMIN_PORTAL_REPOSITORY_COMMIT_LINK = 'https://github.com/oakslab/plotify-admin/commit/';
const ADMIN_PORTAL_REPOSITORY_RELEASES_LINK =
    'https://github.com/oakslab/plotify-admin/releases/tag/v';
const BFF_UNKNOWN_HASH = 'unknownCommitHash';
const commitHash = process.env.RAZZLE_COMMIT_HASH;
const adminPortalVersion = getAdminPortalVersion();

const sliceHash = (hashString: string): string => hashString.slice(0, 7);

const ServerInfo = () => (
    <Query query={QUERY_SERVER_INFO} pollInterval={POLL_INTERVAL}>
        {({ data, loading }) => {
            if (loading) return <div>Loading...</div>;
            // if (error) return <div>Error</div>;

            const {
                version: { currentVersion },
            } = data;

            const getEnvironmentLabel = () => {
                if (isLocalBuild()) return 'Local';
                if (isStaging()) return 'Staging';
                if (isDevelopment()) return 'Development';
                return '';
            };

            return (
                <Styled.Container>
                    <Styled.Info>
                        <Styled.Label>Environment:</Styled.Label>
                        <Styled.Label>{getEnvironmentLabel()}</Styled.Label>
                    </Styled.Info>
                    <Styled.Info>
                        <Styled.Label>API Version:</Styled.Label>
                        <div>
                            <Styled.Anchor
                                href={`${ADMIN_BFF_REPOSITORY_RELEASES_LINK}${currentVersion}`}
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                {currentVersion}
                            </Styled.Anchor>

                            {data.buildHash !== BFF_UNKNOWN_HASH && (
                                <Styled.Anchor
                                    href={`${ADMIN_BFF_REPOSITORY_COMMIT_LINK}${data.buildHash}`}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                >
                                    {`(${sliceHash(data.buildHash)})`}
                                </Styled.Anchor>
                            )}
                        </div>
                    </Styled.Info>
                    <Styled.Info>
                        <Styled.Label>Portal Version:</Styled.Label>
                        <div>
                            <Styled.Anchor
                                href={`${ADMIN_PORTAL_REPOSITORY_RELEASES_LINK}${adminPortalVersion}`}
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                {adminPortalVersion}
                            </Styled.Anchor>
                            {commitHash && (
                                <Styled.Anchor
                                    href={`${ADMIN_PORTAL_REPOSITORY_COMMIT_LINK}${commitHash}`}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                >
                                    {`(${sliceHash(commitHash)})`}
                                </Styled.Anchor>
                            )}
                        </div>
                    </Styled.Info>
                    <Styled.Info>
                        <Styled.Label>Last Reset:</Styled.Label>
                        {moment(data.databaseResetTime).format(DATE_FORMAT)}
                    </Styled.Info>
                    <Styled.Info>
                        <Styled.Label>Last Deploy:</Styled.Label>
                        {moment(data.deployDateTime).format(DATE_FORMAT)}
                    </Styled.Info>
                </Styled.Container>
            );
        }}
    </Query>
);

export default ServerInfo;
