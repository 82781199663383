// @flow
import styled from 'styled-components';

export const Container = styled.header`
    display: flex;
    flex: 1;
    height: 100%;

    > h1 {
        font-size: 22px;
        line-height: 22px;
        font-weight: bold;
        margin-bottom: 16px;
    }

    justify-content: space-between;
    align-items: flex-end;

    & > .anticon:not(:last-child) {
        margin-right: 20px;
    }

    & .add-entity-button {
        margin-right: 20px;
        border: unset;
    }

    .ant-popover-inner-content {
        padding: 0;
    }
`;

export const MenuArrow = styled.span`
    padding: 15px 0;

    :hover {
        cursor: pointer;
    }
`;
