// @flow
import styled from 'styled-components';

export const LogoWrapper = styled.div`
    height: 64px;
`;

export const Logo = styled.img`
    padding: 24px 16px 16px 24px;
    width: 100%;
    max-width: 180px;
`;
