import React from 'react';
import { Layout } from 'antd';
import { Switch, Route, Redirect } from 'react-router-dom';
import { withApollo } from '@apollo/client/react/hoc';

import Header from '@project/components/Header';
import NavigationMenuGQL from '@project/components/NavigationMenu/graphql';
import ServerInfo from '@project/components/ServerInfo';

import { PROTECTED_ENVIRONMENTS } from '@project/utils/constants';

import loadable from '@loadable/component';

type TRouteConfig = {
    path: string;

    component?: Function;
    render?: Function;
    exact?: boolean;
};

const routes: Array<TRouteConfig> = [
    {
        exact: true,
        path: '/',
        component: loadable(() => import('./Plots')),
    },
    {
        path: '/plots',
        component: loadable(() => import('./Plots')),
    },
    {
        path: '/properties',
        component: loadable(() => import('./Properties')),
    },
    {
        path: '/marketplace',
        component: loadable(() => import('./Marketplace')),
    },
    {
        path: '/verifications',
        component: loadable(() => import('./Verifications')),
    },
    {
        path: '/partners',
        component: loadable(() => import('./Partners')),
    },
    {
        path: '/investors',
        component: loadable(() => import('./Investors')),
    },
    {
        path: '/users',
        component: loadable(() => import('./Users')),
    },
    {
        path: '/services',
        component: loadable(() => import('./Services')),
    },
    {
        path: '/experimental',
        component: loadable(() => import('./EXPERIMENTAL')),
    },
    {
        path: '/my-account',
        component: loadable(() => import('./MyAccount')),
    },
    {
        path: '/promo',
        component: loadable(() => import('./Promo')),
    },
];

const filterRoutes =
    (restrictions: Array<string>) =>
    (routeList: Array<TRouteConfig>): Array<TRouteConfig> => {
        if (PROTECTED_ENVIRONMENTS.includes(process.env.RAZZLE_ENVIRONMENT)) {
            return routeList.filter(route => !restrictions.includes(route));
        }

        return routeList;
    };

const filterRoutesByRestrictions = filterRoutes(['/services', '/experimental']);

const Root = () => {
    // Admin notifications not in use, needs to be updated to new firebase sdk
    // const registerFCMToken = async () => {
    //     try {
    //         const token = await firebase.getToken();
    //         if (token) {
    //             client.mutate({
    //                 mutation: SAVE_MESSAGING_TOKEN,
    //                 variables: { token },
    //             });
    //         }
    //     } catch (error) {
    //         console.log('registerFCM error', error);
    //         throw error;
    //     }
    // };
    //
    // useEffect(() => {
    //     registerFCMToken();
    // }, []);

    return (
        <Layout style={{ minHeight: '100vh', minWidth: '1200px' }}>
            <Layout.Sider
                trigger={null}
                width={256}
                style={{
                    overflow: 'auto',
                    height: '100vh',
                    position: 'fixed',
                    zIndex: 1,
                    background: 'white',
                }}
            >
                <NavigationMenuGQL />
                <ServerInfo />
            </Layout.Sider>
            <Layout style={{ marginLeft: 256 }}>
                <Layout.Header style={{ background: 'white' }}>
                    <Header />
                </Layout.Header>
                <Layout.Content style={{ position: 'relative' }}>
                    <Switch>
                        {filterRoutesByRestrictions(routes).map(route => (
                            <Route key={route.path} {...route} />
                        ))}
                        <Redirect to="/404" />
                    </Switch>
                </Layout.Content>
            </Layout>
        </Layout>
    );
};

export default withApollo(Root);
