import styled from 'styled-components';

export const ContainerButton = styled.div`
    flex: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    border: none;
    background: unset;
    cursor: pointer;
    gap: 8px;

    border-radius: 4px;

    padding: 8px 12px;

    margin-bottom: 8px;

    font-size: 16px;
    line-height: 16px;
    font-weight: bold;
    :hover {
        background: ${({ theme }) => theme.backgroundLight};
        scale: 1.03;
    }
`;
