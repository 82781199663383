// @flow
// Todo: Temporary filthy hack
export const PARTNER_CATEGORIES = [
    {
        path: 'property-management',
        label: 'Property Management',
        id: '60f7315e-f97e-4f8b-932c-4e6f0ee8781a',
    },
    { path: 'sourcing', label: 'Sourcing', id: 'b6d0e967-b206-4e49-af82-83e835dc49f9' },
];
