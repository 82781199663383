// @flow
import React from 'react';
import { Query } from '@apollo/react-components';
import gql from 'graphql-tag';
import * as R from 'ramda';

import AvatarWithUsername from '.';
import Auth0 from '../../../services/Auth0';

import logger from '@project/utils/logger';

const QUERY_AVATAR_WITH_USERNAME = gql`
    query avatarWithUsername {
        profile {
            id
            fullName
            picture
        }
    }
`;

type Props = {
    openMenu: Function;
};

// TODO: how to deal with username when we are fetching data?
const AvatarWithUsernameGQL = ({ openMenu }: Props) => (
    <Query query={QUERY_AVATAR_WITH_USERNAME}>
        {({ data, error }) => {
            if (error) {
                logger.error(
                    'Invalid user. redirecting to login page...',
                    error,
                    logger.DISPLAY_MESSAGE
                );

                setTimeout(() => {
                    Auth0.logout();
                }, 1000);
            }

            const id = R.pathOr('', ['profile', 'id'], data);
            const fullName = R.pathOr('Username', ['profile', 'fullName'], data);
            const avatar = R.pathOr('', ['profile', 'picture'], data);

            return (
                <AvatarWithUsername
                    id={id}
                    openMenu={openMenu}
                    username={fullName}
                    avatar={avatar}
                />
            );
        }}
    </Query>
);

export default AvatarWithUsernameGQL;
