// @flow
export const ALL_MONTHS = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

export const PROTECTED_ENVIRONMENTS = ['staging', 'demo', 'production'];

/**
 * @deprecated
 *
 * @see /src/client/utils/validations/errorMessages.js
 */
export const ERROR_MESSAGES = Object.freeze({
    REQUIRED: 'Required',
    INVALID_INPUT: 'Invalid input',
    INVALID_EMAIL_FORMAT: 'Invalid email format',
    POSITIVE_NUMBER: 'Should be a positive number',
});

export const TOOLTIP_MESSAGES = Object.freeze({
    PROFILE_IMAGE:
        'It is recommended to upload image with aspect ration 1:1. Otherwise it might happen that the image will be deformed.',
});

export const NOT_AVAILABLE = 'N/A';

export const CURRENCY_CODES = Object.freeze({
    GBP: 'GBP',
    USD: 'USD',
    EUR: 'EUR',
});

export const COUNTRY_TO_CURRENCY = Object.freeze({
    USA: CURRENCY_CODES.USD,
    GBR: CURRENCY_CODES.GBP,
    IND: CURRENCY_CODES.EUR,
});

export const STATE_NAMES = Object.freeze({
    USA: 'United States',
});

export const STATE_ISO_CODES = Object.freeze({
    USA: 'USA',
});

export const ENUM_NAMES = Object.freeze({
    ARTICLE_CATEGORIES: 'enumArticleCategories',
    PARTNERS: 'enumPartners',
    PARTNER_TYPES: 'enumPartnerTypes',
    PROPERTY_MANAGEMENT_PARTNERS: 'enumPropertyManagementPartners',
    MARKET_PARTNERS: 'enumMarketPartners',
    BEDROOMS_COUNT: 'enumBedroomsCount',
    ROOM_TYPES: 'enumRoomTypes',
    PROPERTY_TYPES: 'enumPropertyTypes',
    BATHROOMS_COUNT: 'enumBathroomsCount',
    LOAN_PAYMENT_FREQUENCIES: 'enumLoanPaymentFrequencies',
    PROPERTY_CONTAINERIZATION_STATUS: 'enumPropertyContainerizationStatus',
    PLOT_CONTAINERIZATION_STATUS: 'enumPlotContainerizationStatus',
    REJECT_REASONS: 'enumRejectReasons',
    TRANSACTION_TYPES: 'enumTransactionTypes',
    PROPERTIES: 'enumProperties',
    OP_FIN_CATEGORIES: 'enumOperationalFinancialCategories',
    OP_FIN_DETAILED_CATEGORIES: 'enumOperationalFinancialDetailedCategories',
    ROLES: 'enumRoles',
    PERMISSIONS: 'enumPermissions',
    INVESTORS: 'enumInvestors',
    USERS: 'enumUsers',
    BOOLEAN: 'enumBoolean',
    COUNTRIES: 'enumCountries',
    STATES: 'enumStates',
});

export const ENUM_OPERATIONAL_FINANCIAL_STATUS = Object.freeze({
    DRAFT: 'draft',
    PUBLISHED: 'published',
});
